// src/components/Home.js
import React from 'react';
import Carousel from '../components/Carousel.js';

function Onas() {
  return (

    <div>
      <div className="container mt-4 o-nas">
        <h1>Vítejte v Leifheit – vašem spolehlivém partnerovi pro domácnost!</h1>
        <div className="offset-2 col-8">
        <p>Jsme značkou, která se již více než šedesát let specializuje na inovativní a kvalitní produkty pro vaši domácnost, a v České republice působíme více než třicet let. Naše značka je synonymem pro efektivitu, design a jednoduchost, a to vše s ohledem na vaše potřeby.</p>
        <p>Naše cesty začaly v malé německé dílně a postupně jsme se stali světovým lídrem v oblasti vybavení a příslušenství pro domácnost. Dnes můžeme s hrdostí říct, že naše produkty pomáhají domácnostem po celém světě šetřit čas a energii.</p>
        <p>Naše hodnoty spočívají v kvalitě, spolehlivosti a trvale udržitelném přístupu. Klademe zvláštní důraz na ekologii a snažíme se minimalizovat negativní dopady našich produktů na životní prostředí.</p>
        <p>Srdcem našeho úspěchu jsou naši zaměstnanci, kteří s vášní pracují na tom, aby vám poskytli nejlepší produkty a služby.</p>
        <p>Věříme, že každá domácnost si zaslouží praktická a inteligentní řešení pro usnadnění každodenních úkolů. S našimi výrobky můžete mít jistotu, že vám pomůžeme udělat váš domov ještě příjemnějším a efektivnějším.</p>
        <p>Děkujeme vám za důvěru, kterou jste nám věnovali po celá desetiletí, a těšíme se na to, že vás budeme provázet naší cestou, když si budujete svůj domov.</p>
        <p>Vaše spokojenost je naším cílem, a jsme tu, abychom vám pomohli dosáhnout toho, co je pro vás důležité. Vítejte v rodině Leifheit!</p>
        </div>
      </div>
      <Carousel />

    </div>
  );
}

export default Onas;
