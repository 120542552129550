import React from 'react';
import TextCard from './TextCard';
import image1 from '../assets/images/60-years.png';
import image2 from '../assets/images/german-quality.png';
import image3 from '../assets/images/extended-warranty.png';
import image4 from '../assets/images/innovation.png';

function Text() {
  return(
  <div className="grey">
    <div className="row container text-cards">
        <TextCard
          imageUrl={image1}
          alt="60 let naší společnosti"
          header="60 let naší společnosti"
          text="Přes 60 let vám usnadňujeme váš každodenní domácí život"
          />
        <TextCard
        imageUrl={image2}
        alt="60 let naší společnosti"
        header="80 % výrobků je vyrobeno v Čechách"
        text="80 % výrobků značky Leifheit je s láskou vyrobeno v Blatné v Jihočeském kraji. S důrazem na nejvyšší standardy, neseme německou preciznost a kvalitu do každého výrobku."
          />
        <TextCard
          imageUrl={image3}
          alt="60 let naší společnosti"
          header="Vysoká spokojenost zákazníků"
          text="Zákazníci jsou pro nás na prvním místě. Díky našim kvalitním výrobkům a péči o zákazníka jsme si vydobyli vysokou spokojenost a loajalitu našich klientů. Vaše spokojenost je pro nás nejlepší odměnou."
          />
        <TextCard
            imageUrl={image4}
            alt="60 let naší společnosti"
            header="Ekologická a společenská odpovědnost"
            text="Jsme si vědomi své odpovědnosti vůči životnímu prostředí. Naše výrobky jsou navrhovány s ohledem na udržitelnost a snižování ekologického otisku. Snažíme se minimalizovat negativní dopad na planetu a podporovat šetrné postupy."
          />
    </div>
  </div>



  )

};

export default Text;
