// src/components/Card.js
// Kachle na HP

import React from 'react';

function Card(props) {
  return (
    <div className="card">
      <img src={props.imageUrl} className="card-img" alt={props.alt} />
      <div className="card-img-overlay">
        <h5 className="card-title">{props.title}</h5>
        <a href={props.buttonLink} className="btn move_right">{props.buttonText}</a>
      </div>
    </div>
  );
}

export default Card;
