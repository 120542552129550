// src/components/Home.js
import React from 'react';

function Uvod() {
  return (

    <div>
      <div className="container mt-4">
        <h1>Welcome to my website</h1>
        <p>This is a sample React Bootstrap website. O nás hošku.</p>

      </div>
    </div>
  );
}

export default Uvod;
