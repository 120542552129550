import React, { useState } from 'react';
import MapContainer from '../components/MapContainer.js';

function Partneri() {
  const [data] = useState([
   {
     id: 0, latitude: 49.5299281, longitude: 14.8072769,
         name: 'JIH-Trade, s.r.o.',
         email: 'holub.prodej@seznam.cz',
         street: 'Husovo náměstí 675',
         city: 'Mladá Vožice',
         zip: '391 43',

       },{		id: 1, latitude: 50.0569378, longitude: 14.5075503,
         name: 'Jestar spol. s.r.o.',
         email: 'jestar@seznam.cz',
         street: 'Topolová 2915',
         city: 'Praha 10',
         zip: '106 00',

       },{		id: 2, latitude: 50.1445125, longitude: 14.0912797,
         name: 'Mercato Trade s.r.o., Domácí potřeby',
         email: 'knotek@mercatotrade.cz',
         street: 'Československé armády 3235',
         city: 'Kladno',
         zip: '272 01',

       },{		id: 3, latitude: 48.7292836, longitude: 14.6209586,
         name: 'Karel Malák',
         email: 'karelmalak@seznam.cz',
         street: 'Benešov nad Černou 2',
         city: 'Benešov nad Cernou 2',
         zip: '382 82',

       },{		id: 4, latitude: 49.9976333, longitude: 17.4773889,
         name: 'ATEXKOM s.r.o.',
         email: 'market@atexkom.com',
         street: 'Krnovská 1020/16',
         city: 'Bruntál',
         zip: '792 01',

       },{		id: 5, latitude: 49.5837186, longitude: 15.9369600,
         name: 'KINSKÝ Ždár, a.s.',
         email: 'info@kinsky-zdar.cz',
         street: 'Zámek 1/1',
         city: 'Ždár nad Sázavou',
         zip: '591 01',

       },{		id: 6, latitude: 50.7667000, longitude: 15.0540789,
         name: 'Kování pro Váš domov',
         email: 'kovaniprovasdomov@m-mgr.cz',
         street: '1. máje 8',
         city: 'Liberec',
         zip: '460 14',

       },{		id: 7, latitude: 49.2317378, longitude: 13.5187036,
         name: 'Náradí Janoušek s.r.o.',
         email: 'srouby@naradijanousek.cz',
         street: 'Klostermannova 121',
         city: 'Sušice',
         zip: '342 01',

       },{		id: 8, latitude: 49.9737136, longitude: 16.4015889,
         name: 'HAITECO CZ s.r.o.',
         email: 'prodejna@haiteco.cz',
         street: 'Na Štepnici 485',
         city: 'Ústí nad Orlicí',
         zip: '562 01',

       },{		id: 9, latitude: 50.1438853, longitude: 14.0660228,
         name: 'Železárství Soukup s.r.o.',
         email: 'zelezarstvisoukup@seznam.cz',
         street: 'Emila Zahrádky 865',
         city: 'Kladno 4',
         zip: '272 04',

       },{		id: 10, latitude: 48.8510217, longitude: 17.1282192,
         name: 'TVARBET MORAVIA, a.s.',
         email: 'kuchynka@tvarbet-moravia.cz',
         street: 'Dolní Valy 4',
         city: 'Hodonín',
         zip: '695 01',

       },{		id: 11, latitude: 49.3541825, longitude: 16.0151442,
         name: 'AMICUS, s.r.o.',
         email: 'lenka.amicus@seznam.cz',
         street: 'Novosady 111/54',
         city: 'Velké Mezirící',
         zip: '594 01',

       },{		id: 12, latitude: 49.6794278, longitude: 18.6678011,
         name: 'BABILON EDVARD - BERM',
         email: 'edvardbabilon@email.cz',
         street: 'Nám.Míru 634',
         city: 'Třinec',
         zip: '739 61',

       },{		id: 13, latitude: 49.1427833, longitude: 15.0034439,
         name: 'Železárství Jaroš',
         email: 'hradec@zelezarstvi-jaros.cz',
         street: 'Rybniční 187',
         city: 'Jindřichův Hradec',
         zip: '377 04',

       },{		id: 14, latitude: 49.5929344, longitude: 17.2518367,
         name: 'DOMA - R s.r.o.',
         email: 'doma.vanickova@iol.cz',
         street: 'Dolní nám. 48',
         city: 'Olomouc',
         zip: '772 00',

       },{		id: 15, latitude: 50.3140331, longitude: 13.5347228,
         name: 'V.H.V. s.r.o.',
         email: 'faktury@vhv.cz',
         street: 'Husova 3058',
         city: 'Žatec',
         zip: '438 01',

       },{		id: 16, latitude: 49.5299281, longitude: 14.8072769,
         name: 'Holub Hobbymarket',
         email: 'info@technikaprozahradu.cz',
         street: 'Husova námestí 675',
         city: 'Mladá Vožice',
         zip: '391 43',

       },{		id: 17, latitude: 49.3084319, longitude: 18.1107197,
         name: 'Frydrych-pap, s.r.o.',
         email: 'frydrych@frydrych-pap.cz',
         street: 'Huslenky 282',
         city: 'Huslenky',
         zip: '756 02',

       },{		id: 18, latitude: 49.0105039, longitude: 13.9984608,
         name: 'Obchodservis Prachatice, s.r.o',
         email: 'kapral@obchodkapral.cz',
         street: 'Jánská 122',
         city: 'Prachatice',
         zip: '383 01',

       },{		id: 19, latitude: 49.4780864, longitude: 17.1130692,
         name: 'PLASTIMEX Prostějov spol.s r.o',
         email: 'info@plastimexpv.cz',
         street: 'Sladkovského 3065/14',
         city: 'Prostějov',
         zip: '796 04',

       },{		id: 20, latitude: 49.8691061, longitude: 18.3699750,
         name: 'ESAM',
         email: 'zuzanakolderova@esam.cz',
         street: 'Odbojová 1686',
         city: 'Rychvald',
         zip: '735 32',

       },{		id: 21, latitude: 49.8268617, longitude: 18.2610275,
         name: 'DOMA - R s.r.o.',
         email: 'urbanova@doma-r.cz',
         street: 'Mgr.Šrámka 5',
         city: 'Ostrava',
         zip: '702 00',

       },{		id: 22, latitude: 49.7279050, longitude: 17.2980472,
         name: 'Pavel BUNDIL',
         email: 'hrnce@hrnce.cz',
         street: 'Olomoucká 1294/13',
         city: 'Šternberk',
         zip: '785 01',

       },{		id: 23, latitude: 49.7047533, longitude: 14.8984956,
         name: 'Lucie Vencelová - dom.potřeby',
         email: 'lucie.vencelova@centrum.cz',
         street: 'Prokopova 1644',
         city: 'Vlašim',
         zip: '258 01',

       },{		id: 24, latitude: 49.7704700, longitude: 17.1145706,
         name: 'DOSTÁL CZ s.r.o.',
         email: 'kuchynka.unicov@dostal.cz',
         street: 'Obchodní centrum Tescoma, domácí potřeby, Hrubého 704',
         city: 'Uničov',
         zip: '783 91',

       },{		id: 25, latitude: 48.7643783, longitude: 16.0639711,
         name: 'Domino - Excalibur City s.r.o.',
         email: 'info@plastimexpv.cz',
         street: 'Chvalovice - Hatě 183',
         city: 'Znojmo',
         zip: '669 02',

       },{		id: 26, latitude: 50.0738986, longitude: 14.4418653,
         name: 'DOMFIDA, s.r.o.',
         email: 'budecska@domacipotreby.cz',
         street: 'Budečská 944/9',
         city: 'Praha 2 - Vinohrady',
         zip: '120 00',

       },{		id: 27, latitude: 50.0467606, longitude: 14.4871386,
         name: 'Domino - Obchodní dům Centrum',
         email: 'info@plastimexpv.cz',
         street: 'Hlavní 2459',
         city: 'Praha 4',
         zip: '141 00',

       },{		id: 28, latitude: 49.6882942, longitude: 14.0108786,
         name: 'Domácí potřeby-Franta',
         email: 'info@domacipotreby-franta.cz',
         street: 'Pražská 128',
         city: 'Příbram 1',
         zip: '261 01',

       },{		id: 29, latitude: 50.2630842, longitude: 17.2398183,
         name: 'Prokauf Komplex s.r.o.',
         email: 'info@izelezarstvi.cz',
         street: 'Polská 20',
         city: 'Česká Ves',
         zip: '790 81',

       },{		id: 30, latitude: 49.7842492, longitude: 14.6876397,
         name: 'Domino',
         email: 'peltramova@plastimexpv.cz',
         street: 'Nová Pražská 1903',
         city: 'Benešov',
         zip: '256 01',

       },{		id: 31, latitude: 50.1339778, longitude: 14.1404611,
         name: 'Mercato Trade s.r.o.',
         email: 'knotek@mercatotrade.cz',
         street: 'Generála Selnera 3255',
         city: 'Kladno',
         zip: '272 01',

       },{		id: 32, latitude: 49.6990164, longitude: 17.0752086,
         name: 'DOSTÁL CZ s.r.o.',
         email: 'obchod@dostal.cz',
         street: 'Palackého 853/2',
         city: 'Litovel',
         zip: '784 01',

       },{		id: 33, latitude: 48.9951883, longitude: 16.8603867,
         name: 'ZEVYT s.r.o.',
         email: 'zevyt.obchod@gmail.com',
         street: 'Bratří Mrštíků 81/7',
         city: 'Klobouky u Brna',
         zip: '691 72',

       },{		id: 34, latitude: 49.9019322, longitude: 18.3574039,
         name: 'ESAM',
         email: 'kvetkanowakova@esam.cz',
         street: 'Studentská 182',
         city: 'Bohumín',
         zip: '735 81',

       },{		id: 35, latitude: 50.6571833, longitude: 14.7245947,
         name: 'Rampas Aleš',
         email: 'ales.rampas@tiscali.cz',
         street: 'Mírová 125',
         city: 'Mimoň',
         zip: '417 24',

       },{		id: 36, latitude: 48.8297550, longitude: 16.4011581,
         name: 'Božena Sedláková - LUMIS',
         email: 'lumis.hracky@volny.cz',
         street: 'Náměstí Míru 4',
         city: 'Hrušovany nad Jevišovkou',
         zip: '671 67',

       },{		id: 37, latitude: 49.1954206, longitude: 16.6119194,
         name: 'VICHR spol. s r.o.',
         email: 'dum@vichr.net',
         street: 'Kobližná 19',
         city: 'Brno',
         zip: '602 00',

       },{		id: 38, latitude: 49.4318114, longitude: 15.2242439,
         name: 'Zdeněk Jaroš Ml. Železárství',
         email: 'jaros@zelezarstvi-jaros.cz',
         street: 'Palackého 67',
         city: 'Pelhřimov',
         zip: '393 01',

       },{		id: 39, latitude: 49.7877606, longitude: 18.2570994,
         name: 'Prumyslové zboží s r.o.',
         email: 'dompo@volny.cz',
         street: 'Horní 1457/57',
         city: 'Ostrava - Hrabůvka',
         zip: '700 30',

       },{		id: 40, latitude: 49.8700725, longitude: 18.4233619,
         name: 'ESAM',
         email: 'tomasskrizovsky@esam.cz',
         street: 'Masarykova třída 1003',
         city: 'Orlová-Lutyně',
         zip: '735 14',

       },{		id: 41, latitude: 50.1536647, longitude: 13.9003489,
         name: 'Železárství Soukup s.r.o.',
         email: 'zelezarstvisoukup@seznam.cz',
         street: 'Komenského náměstí 107',
         city: 'Nové Strašecí',
         zip: '271 01',

       },{		id: 42, latitude: 50.6347003, longitude: 15.1535431,
         name: 'Milena Súkeníková',
         email: 'dpmimi@seznam.cz',
         street: 'Roudný 35',
         city: 'Frýdštejn',
         zip: '463 42',

       },{		id: 43, latitude: 49.4729247, longitude: 17.1201892,
         name: 'Vladislav Škvaril - JASKVA',
         email: 'Filip.skvaril@seznam.cz',
         street: 'Svatoplukova 23',
         city: 'Prostějov',
         zip: '796 01',

       },{		id: 44, latitude: 49.5759511, longitude: 15.9384197,
         name: 'OD-Dr. Kinský',
         email: 'info@kinsky-zdar.cz',
         street: 'Bezručova 4',
         city: 'Žďár nad Sázavou 2',
         zip: '591 02',

       },{		id: 45, latitude: 49.0245744, longitude: 17.6473856,
         name: 'Domácí potřeby K&amp;K, s.r.o.',
         email: 'vaculikova@dpkk.cz',
         street: 'Masarykovo nám.103',
         city: 'Uherský Brod',
         zip: '688 01',

       },{		id: 46, latitude: 50.3140401, longitude: 13.5323482,
         name: 'V.H.V. s.r.o.',
         email: 'faktury@vhv.cz',
         street: 'Václava Majera 2999',
         city: 'Louny',
         zip: '440 01',

       },{		id: 47, latitude: 50.1044783, longitude: 14.3952508,
         name: 'Železárství na Podbabské s.r.o.',
         email: 'tovarovadp@seznam.cz',
         street: 'Jugoslávských partyzánů 18',
         city: 'Praha 6',
         zip: '160 00',

       },{		id: 48, latitude: 49.5464131, longitude: 18.2136050,
         name: 'Eliška Kahánková',
         email: 'ekahankova@centrum.cz',
         street: 'Horní 26',
         city: 'Frenštát pod Radhoštěm',
         zip: '744 01',

       },{		id: 49, latitude: 49.2026139, longitude: 15.8898525,
         name: 'Haus Spezi NC Stop Shop',
         email: 'jarosova@hausspezi.cz',
         street: 'Spojovací ul.',
         city: 'Třebíč',
         zip: '674 01',

       },{		id: 50, latitude: 49.0888836, longitude: 18.0238444,
         name: 'EUROHITY s.r.o.',
         email: 'eurohity@eurohity.cz',
         street: '1.května 1034',
         city: 'Brumov-Bylnice',
         zip: '763 31',

       },{		id: 51, latitude: 50.4293236, longitude: 15.8184275,
         name: 'INZAT spol. s r.o.',
         email: 'kuchynka.inzat@seznam.cz',
         street: 'Preslova 441',
         city: 'Dvůr Králové',
         zip: '544 01',

       },{		id: 52, latitude: 49.6747125, longitude: 18.3405403,
         name: 'DOMA - R s r.o.',
         email: 'skotnicova@doma-r.cz',
         street: 'Ostravská 1552',
         city: 'Frýdek - Místek',
         zip: '738 01',

       },{		id: 53, latitude: 49.4231950, longitude: 15.6055172,
         name: 'Zepo Jihlava,spol.s r.o.',
         email: 'nakup@zepojihlava.cz',
         street: 'Na Hranici 8',
         city: 'Jihlava',
         zip: '586 02',

       },{		id: 54, latitude: 50.0868892, longitude: 16.4655256,
         name: 'Železo Zdeněk- domácí potřeby',
         email: 'martina.936@seznam.cz',
         street: 'Masarykovo nám.5',
         city: 'Žamberk',
         zip: '564 01',

       },{		id: 55, latitude: 49.6384711, longitude: 14.6410347,
         name: 'Jan Procházka',
         email: 'tescoma.votice@email.cz',
         street: 'Husova 156',
         city: 'Votice',
         zip: '259 01',

       },{		id: 56, latitude: 49.1480119, longitude: 18.0224853,
         name: 'EUROHITY s.r.o.',
         email: 'eurohity@eurohity.cz',
         street: 'Cyrilometodějská 1003',
         city: 'Valašské Klobouky',
         zip: '766 01',

       },{		id: 57, latitude: 50.0766486, longitude: 14.4220681,
         name: 'GASTROŠULC s.r.o.',
         email: 'gastrosulc@gastrosulc.cz',
         street: 'Malá Štěpánská 1932/3',
         city: 'Praha 2, Nové Město',
         zip: '120 00',

       },{		id: 58, latitude: 49.7807006, longitude: 18.4318008,
         name: 'DOMA - R s.r.o.',
         email: 'janosovska@doma-r.cz',
         street: 'Obchodní dům Elán',
         city: 'Havířov',
         zip: '736 01',

       },{		id: 59, latitude: 49.3327817, longitude: 17.5790142,
         name: 'Obchodní dům Morava Holešov, s.r.o.',
         email: 'kovomat@odmorava.cz',
         street: 'Nám. E. Beneše 7',
         city: 'Holešov',
         zip: '769 01',

       },{		id: 60, latitude: 49.5772150, longitude: 17.2910719,
         name: 'DOMA - R s r.o.',
         email: 'doma-r@doma-r.cz',
         street: 'Sladkovského 659/40',
         city: 'Holice',
         zip: '779 00',

       },{		id: 61, latitude: 49.9737136, longitude: 16.4015889,
         name: 'HAITECO CZ s.r.o.',
         email: 'prodejna@haiteco.cz',
         street: 'Na Štepnici 485',
         city: 'Ústí nad Orlicí',
         zip: '562 06',

       },{		id: 62, latitude: 49.5772150, longitude: 17.2910719,
         name: 'DMH a Kloupar spol. s r.o.',
         email: 'kloupar@tiscali.cz',
         street: 'Sladkovského 659/40',
         city: 'Olomouc - Holice',
         zip: '783 71',

       },{		id: 63, latitude: 50.2423858, longitude: 15.4913681,
         name: 'Jindřich Jireš - železárství',
         email: 'jires@jires.cz',
         street: 'U Památníku 1147',
         city: 'Nový Bydžov',
         zip: '504 01',

       },{		id: 64, latitude: 48.7359775, longitude: 14.4843350,
         name: 'Barvy-laky Karel Malák',
         email: 'info@karelmalak.cz',
         street: 'Omlenická 177',
         city: 'Kaplice',
         zip: '382 41',

       },{		id: 65, latitude: 49.6384711, longitude: 14.6410347,
         name: 'Průmyslové zboží Procházka Jan',
         email: 'elektro.zelezarstvi@mybox.cz',
         street: 'Husova 156',
         city: 'Votice',
         zip: '259 01',

       },{		id: 66, latitude: 49.3541786, longitude: 16.0144150,
         name: 'AMICUS-zahrádka',
         email: 'amicus@cbox.cz',
         street: 'Kostelní 1900/1',
         city: 'Velké Meziříčí',
         zip: '594 01',

       },{		id: 67, latitude: 49.0066489, longitude: 14.7581894,
         name: 'Dušák s.r.o.',
         email: 'dusak.tr@seznam.cz',
         street: 'Táboritská 564',
         city: 'Třeboň',
         zip: '379 01',

       },{		id: 68, latitude: 49.6954925, longitude: 14.9062622,
         name: 'GASTROŠULC s.r.o.',
         email: 'gastrosulc@gastrosulc.cz',
         street: 'Lidická 1636',
         city: 'Vlašim',
         zip: '258 01',

       },{		id: 69, latitude: 49.8661211, longitude: 18.3763114,
         name: 'ESAM',
         email: 'zuzanakolderova@esam.cz',
         street: 'Orlovská 404',
         city: 'Rychvald',
         zip: '735 32',

       },{		id: 70, latitude: 49.9382367, longitude: 17.9033592,
         name: 'DOMA - R s r.o.',
         email: 'doma.hamplova@iol.cz',
         street: 'Horní náměstí 58',
         city: 'Opava',
         zip: '746 01',

       },{		id: 71, latitude: 49.2880200, longitude: 16.2282486,
         name: 'Amicus, s.r.o.',
         email: 'lenka.amicus@seznam.cz',
         street: 'Masarykovo nám. 1',
         city: 'Velká Bíteš',
         zip: '595 01',

       },{		id: 72, latitude: 49.0554461, longitude: 16.6385497,
         name: 'ZEPO s.r.o.',
         email: 'obchod.blucina@zepojihlava.cz',
         street: 'Na Cézavě 627',
         city: 'Blučina',
         zip: '664 56',

       },{		id: 73, latitude: 49.2599192, longitude: 15.2401775,
         name: 'ELEKTRA - Jana Janková',
         email: 'ElektraJankova@seznam.cz',
         street: 'Palackého nám. 28',
         city: 'Počátky',
         zip: '394 64',

       },{		id: 74, latitude: 49.2239381, longitude: 14.4180342,
         name: 'Elektro Maroušek s.r.o.',
         email: 'elektro.marousek@email.cz',
         street: 'Horní Brašov 266',
         city: 'Týn nad Vltavou',
         zip: '375 01',

       },{		id: 75, latitude: 50.2272578, longitude: 17.2091503,
         name: 'Prokauf Komplex s.r.o.',
         email: 'info@izelezarstvi.cz',
         street: 'Karla Capka 237/4',
         city: 'Jeseník',
         zip: '790 01',

       },{		id: 76, latitude: 49.7189286, longitude: 16.2573250,
         name: 'BÁČA POLIČKA s.r.o.',
         email: 'baumarket@baca.cz',
         street: 'Jilemnického 567-bauMarket',
         city: 'Polička',
         zip: '572 01',

       },{		id: 77, latitude: 49.1421050, longitude: 15.0229778,
         name: 'Mazdos s.r.o.',
         email: 'mazdos@gmail.com',
         street: 'Vajgar 674/III',
         city: 'Jindřichův Hradec',
         zip: '377 04',

       },{		id: 78, latitude: 49.2100056, longitude: 15.8775336,
         name: 'Ing.Josef Prajsner',
         email: 'prijem@hausspezi.cz',
         street: 'Jiráskova 1077',
         city: 'Třebíč',
         zip: '674 01',

       },{		id: 79, latitude: 49.2520144, longitude: 13.9131703,
         name: 'Jirí Milisterfer - AGROM',
         email: 'prodejna@milisterfer.cz',
         street: 'Volyňská 145',
         city: 'Strakonice',
         zip: '386 01',

       },{		id: 80, latitude: 49.7047533, longitude: 14.8984956,
         name: 'Lucie Vencelová',
         email: 'lucie.vencelova@centrum.cz',
         street: 'Prokopova 1644',
         city: 'Vlašim',
         zip: '258 01',

       },{		id: 81, latitude: 49.6781958, longitude: 13.9969158,
         name: 'Roman Haering, domácí potřeby Prodok',
         email: 'pribram@prodok.cz',
         street: 'nám. 17. listopadu 298',
         city: 'Příbram VII',
         zip: '261 01',

       },{		id: 82, latitude: 49.3084319, longitude: 18.1107197,
         name: 'Frydrych-pap, s.r.o.',
         email: 'frydrych@frydrych-pap.cz',
         street: 'Huslenky 282',
         city: 'Huslenky',
         zip: '756 02',

       },{		id: 83, latitude: 49.5485211, longitude: 17.7370642,
         name: 'Stami Hranice s.r.o.',
         email: 'hobby@stamihranice.cz',
         street: 'Skalní 1942',
         city: 'Hranice',
         zip: '753 01',

       },{		id: 84, latitude: 49.7874164, longitude: 14.6966281,
         name: 'Perfecta, spol. s r.o.',
         email: 'prodejna@perfecta-zelezarstvi.cz',
         street: 'Čechova 2123',
         city: 'Benešov',
         zip: '256 01',

       },{		id: 85, latitude: 49.2077256, longitude: 16.1506222,
         name: 'ELEKTRO - ŽELEZÁRSTVÍ DUFEK s.r.o.',
         email: 'elektrodufek@seznam.cz',
         street: 'Třebíčská 974',
         city: 'Námest nad Oslavou',
         zip: '675 71',

       },{		id: 86, latitude: 49.6053933, longitude: 18.1418875,
         name: 'Eliška Kahánková',
         email: 'ekahankova@centrum.cz',
         street: 'Družební 1175/10',
         city: 'Kopřivnice',
         zip: '742 21',

       },{		id: 87, latitude: 49.8522006, longitude: 18.5572908,
         name: 'ESAM',
         email: 'verahumlickova@esam.cz',
         street: 'Kosmonautů 800',
         city: 'Karviná  - Ráj',
         zip: '734 01',

       },{		id: 88, latitude: 49.8365772, longitude: 18.2929308,
         name: 'DOMA - R, s.r.o.',
         email: 'nemethova@doma-r.cz',
         street: 'Masarykovo nám. 15',
         city: 'Ostrava',
         zip: '702 00',

       },{		id: 89, latitude: 49.0488194, longitude: 15.8122114,
         name: 'ZEPO, spol. s.r.o.',
         email: 'nakup@zepojihlava.cz',
         street: 'Husova 189',
         city: 'Moravské Budějovice',
         zip: '676 02',

       },{		id: 90, latitude: 49.3401872, longitude: 17.9932669,
         name: 'ELPOS Josef Tkadlec spol s r.o',
         email: 'skloporcelan@elposvsetin.cz',
         street: 'Dolní náměstí 305',
         city: 'Vsetín',
         zip: '755 01',

       },{		id: 91, latitude: 49.6130731, longitude: 17.2591867,
         name: 'ATEXKOM s.r.o.',
         email: 'daria@atexkom.com',
         street: 'Čelakovského 3/4',
         city: 'Olomouc - Černovír',
         zip: '779 00',

       },{		id: 92, latitude: 50.6122428, longitude: 15.5086831,
         name: 'PKMT s.r.o., Centro',
         email: 'kuchynka@centro1.cz',
         street: 'Krkonošská 1259',
         city: 'Jilemnice',
         zip: '514 01',

       },{		id: 93, latitude: 48.9810100, longitude: 16.5197983,
         name: 'ABK 99 , s.r.o.',
         email: 'abk99@abk99.cz',
         street: 'Komenského 259',
         city: 'Pohořelice',
         zip: '691 23',

       },{		id: 94, latitude: 49.3351381, longitude: 17.9969886,
         name: 'ELPOS Josef Tkadlec spol. s r.',
         email: 'skloporcelan@elposvsetin.cz',
         street: 'Smetanova 1477',
         city: 'Vsetín',
         zip: '755 01',

       },{		id: 95, latitude: 49.2419864, longitude: 13.9080453,
         name: 'Milisterfer s.r.o. - AGROM',
         email: 'prodejna@milisterfer.cz',
         street: 'Radošovice 146',
         city: 'Strakonice',
         zip: '386 01',

       },{		id: 96, latitude: 49.2599192, longitude: 15.2401775,
         name: 'ELEKTRA - Jana Janková',
         email: 'ElektraJankova@seznam.cz',
         street: 'Palackého nám. 28',
         city: 'Počátky',
         zip: '394 64',

       },{		id: 97, latitude: 50.1271361, longitude: 14.4688722,
         name: 'Jestar s.r.o.',
         email: 'jestar@seznam.cz',
         street: 'Střelnicná 1660',
         city: 'Praha 8',
         zip: '182 00',

       },{		id: 98, latitude: 50.7232719, longitude: 15.1692778,
         name: 'Milena Súkeníková - MIMI',
         email: 'dpmimi@seznam.cz',
         street: 'Anenská 3',
         city: 'Jablonec nad Nisou',
         zip: '466 01',

       },{		id: 99, latitude: 50.7377050, longitude: 15.2467053,
         name: 'Jiří Černý - železářství U BLACKA',
         email: 'zelezoblack@seznam.cz',
         street: 'Hlavní 797',
         city: 'Smržovka',
         zip: '468 51',

       },{		id: 100, latitude: 50.0870033, longitude: 16.4658867,
         name: 'Zdenek Železo',
         email: 'zelezo.kuchynka@seznam.cz',
         street: 'Masarykovo nám. 3',
         city: 'Žamberk',
         zip: '564 01',

       },{		id: 101, latitude: 49.2239353, longitude: 16.5952978,
         name: 'DMH a Kloupar s.r.o.',
         email: 'kloupar@tiscali.cz',
         street: 'Palackého 80',
         city: 'Brno',
         zip: '612 00',

       },{		id: 102, latitude: 49.3474281, longitude: 16.0254036,
         name: 'Amicus, s.r.o.',
         email: 'lenka.amicus@seznam.cz',
         street: 'U Tržište 2205',
         city: 'Velké Mezirící',
         zip: '594 01',

       },{		id: 103, latitude: 50.7712558, longitude: 14.2050808,
         name: 'Železárství ABRAHAM s.r.o.',
         email: 'kuchyn@abraham-dc.cz',
         street: 'Ústecká 1975 /12',
         city: 'Děčín',
         zip: '405 02',

       },{		id: 104, latitude: 50.1133264, longitude: 14.6134972,
         name: 'PKMT s.r.o.',
         email: 'kuchynka@centro1.cz',
         street: 'Jívanská 1855/22',
         city: 'Praha 9 - Horní Počernice',
         zip: '193 00',

       },{		id: 105, latitude: 49.4829722, longitude: 17.2822256,
         name: 'Jiří Šilha - PRODOM',
         email: 'silhaprodom@iol.cz',
         street: 'Brodecká 36',
         city: 'Dub nad Moravou',
         zip: '783 75',

       },{		id: 106, latitude: 50.9551417, longitude: 14.5533336,
         name: 'RATIO Rumburk s r.o.',
         email: 'info@ratio-sro.cz',
         street: 'Polské armády 5/1365',
         city: 'Rumburk',
         zip: '408 01',

       },{		id: 107, latitude: 49.6384711, longitude: 14.6410347,
         name: 'Jan Procházka',
         email: 'elektro.zelezarstvi@mybox.cz',
         street: 'Husova 156',
         city: 'Votice',
         zip: '259 01',

       },{		id: 108, latitude: 47.8543031, longitude: 17.7808422,
         name: 'Condor trade, s.r.o.',
         email: 'jana.vitekova@gmail.com',
         street: 'Mlynská 1',
         city: 'Veľký Meder',
         zip: '932 01',

       },{		id: 109, latitude: 49.4394797, longitude: 18.7898672,
         name: 'EURONARADIE',
         email: 'vilezinska@euronaradie.sk',
         street: 'Námestie Slobody 6',
         city: 'Čadca',
         zip: '022 01',

       },{		id: 110, latitude: 49.0817514, longitude: 19.6177956,
         name: 'UNIDOM, s.r.o.',
         email: 'unidom@unidom.sk',
         street: '1.mája 2044',
         city: 'Liptovský Mikuláš',
         zip: '031 01',

       },{		id: 111, latitude: 49.3037989, longitude: 21.3161094,
         name: 'Fecko Dušan - Predajňa u Fecka',
         email: 'bialobokovam@gmail.com',
         street: 'Zahradná 8,Bardejovská Nová Ves',
         city: 'Bardejov',
         zip: '085 01',

       },{		id: 112, latitude: 48.3446303, longitude: 19.6601783,
         name: 'LG KRONO s.r.o.',
         email: 'lgkrono@lgkrono.sk',
         street: 'Zvolenská cesta 5038',
         city: 'Lučenec',
         zip: '984 01',

       },{		id: 113, latitude: 49.0643567, longitude: 18.9205697,
         name: 'Jaroslava Demácková-DEMRO',
         email: 'demro@stonline.sk',
         street: '29. augusta 11',
         city: 'Martin',
         zip: '036 01',

       },{		id: 114, latitude: 48.6249467, longitude: 18.3998033,
         name: 'Záhradné centrum Unimarket',
         email: 'info@zcunimarket.sk',
         street: 'Uherecká 2',
         city: 'Malé Uherce',
         zip: '958 03',

       },{		id: 115, latitude: 48.7211900, longitude: 18.2667300,
         name: 'Ján Daniš -Drogéria',
         email: 'danis123@centrum.sk',
         street: 'K Zornici 1669/19',
         city: 'Bánovce nad Bebravou',
         zip: '957 01',

       },{		id: 116, latitude: 49.2139511, longitude: 19.2977467,
         name: 'Domoss Technika s.r.o.',
         email: 'info@domosstechnika.sk',
         street: 'Aleja Svobody 3054',
         city: 'Dolný Kubín',
         zip: '026 01',

       },{		id: 117, latitude: 49.4424361, longitude: 18.7886086,
         name: 'EURONARADIE Dielňa Záhrada',
         email: 'info@euronaradie.sk',
         street: 'Slobody 3039/4',
         city: 'Čadca',
         zip: '022 01',

       },{		id: 118, latitude: 48.0339781, longitude: 17.3014758,
         name: 'ANDREA SHOP s.r.o.',
         email: 'Gabriel.Alfoldi@andrea-shop.sk',
         street: 'Kasárenská 16',
         city: 'Šamorín',
         zip: '931 01',

       },{		id: 119, latitude: 48.8922117, longitude: 21.6793603,
         name: 'ELA VRANOV, s.r.o.',
         email: 'ela@elastavebniny.sk',
         street: 'Nám. Slobody 1248',
         city: 'Vranov nad Topľou',
         zip: '093 01',

       },{		id: 120, latitude: 48.7017275, longitude: 21.7091072,
         name: 'Iveta Jakubová -TV - PLAY - SERVIS',
         email: 'obchod@tvplay-eshop.sk',
         street: 'Hriadky 32',
         city: 'Hriadky',
         zip: '076 22',

       },{		id: 121, latitude: 48.6287856, longitude: 18.3747997,
         name: 'BAJZIK s.r.o.',
         email: 'bajzik@bajzik.sk',
         street: 'Nitrianska 1640/110',
         city: 'Partizánske',
         zip: '958 01',

       },{		id: 122, latitude: 49.1384097, longitude: 20.4283583,
         name: 'FARBY-LAKY-DROGERIA',
         email: 'bjaloncikovci@gmail.com',
         street: 'Starý trh 6',
         city: 'Kežmarok',
         zip: '060 01',

       },{		id: 123, latitude: 48.5753422, longitude: 19.1520206,
         name: 'VITTEK CENTRUM spol. s r.o.',
         email: 'kevan@vittekcentrum.sk',
         street: 'Lieskovská cesta 6',
         city: 'Zvolen',
         zip: '960 01',

       },{		id: 124, latitude: 48.7033622, longitude: 21.6794858,
         name: 'Iveta Jakubová - TV - PLAY - SERVIS',
         email: 'info@playshop.sk',
         street: 'Kochanovská 246/113',
         city: 'Sečovce',
         zip: '078 01',

       },{		id: 125, latitude: 48.3772347, longitude: 17.5871658,
         name: 'Jaroslav Hudec, Domace potreby, OD Jednota',
         email: 'hudec2@stonline.sk',
         street: 'Trojicne namestie  9',
         city: 'Trnava',
         zip: '917 01',

       },{		id: 126, latitude: 48.1276653, longitude: 17.1154086,
         name: 'František Majtán - EURONICS TPD',
         email: 'bratislava@tpd.sk',
         street: 'Farského 26',
         city: 'Bratislava',
         zip: '851 01',

       },{		id: 127, latitude: 49.1313644, longitude: 20.4299600,
         name: 'Farby-Laky Bjaloncík',
         email: 'bjaloncikovci@post.sk',
         street: 'Generála Štefanika 5',
         city: 'Kežmarok',
         zip: '060 01',

       },{		id: 128, latitude: 49.3022997, longitude: 21.2810417,
         name: 'Dušan Fecko Drogeria',
         email: 'bialobokovam@gmail.com',
         street: 'Pod Vinbargom 1129',
         city: 'Bardejov',
         zip: '085 81',

       },{		id: 129, latitude: 48.6761906, longitude: 21.2997947,
         name: 'PhDr. Marta Šamová - MÁŠA',
         email: 'samova@post.sk',
         street: 'Važecká 8/C',
         city: 'Košice',
         zip: '040 12',

       },{		id: 130, latitude: 48.6761906, longitude: 21.2997947,
         name: 'REMI - DP, s.r.o.',
         email: 'remi-belcakova@mail.t-com.sk',
         street: 'Pionierska 551',
         city: 'Sládkovicovo',
         zip: '925 21',

       },{		id: 131, latitude: 48.7223542, longitude: 18.2603372,
         name: 'Ján Daniš',
         email: 'jandanis123@centrum.sk',
         street: 'M. R. Štefánika 10',
         city: 'Bánovce nad Bebravou',
         zip: '957 01',

       },{		id: 132, latitude: 48.5928475, longitude: 17.8231825,
         name: 'DOMOSS TECHNIKA,a.s.',
         email: 'info@domosstechnika.sk',
         street: 'Bratislavská 11/a',
         city: 'Pieštany',
         zip: '921 01',

       },{		id: 133, latitude: 48.3368561, longitude: 19.6529053,
         name: 'Miroslav Tóth ELEKTRO - M.T.',
         email: 'elektromt.lc@mail.t-com.sk',
         street: 'Gagarínova 15',
         city: 'Lučenec',
         zip: '984 01',

       },{		id: 134, latitude: 48.1487536, longitude: 17.8719344,
         name: 'ANDREA SHOP s.r.o.',
         email: 'Gabriel.Alfoldi@andrea-shop.sk',
         street: 'Hlavná 61',
         city: 'Šaľa',
         zip: '927 01',

       },{		id: 135, latitude: 48.3324069, longitude: 19.6704850,
         name: 'Miroslav Tóth ELEKTRO - M.T.',
         email: 'lucenec@elektromt.sk',
         street: 'Rázusova 85/7',
         city: 'Lučenec',
         zip: '984 01',

       },{		id: 136, latitude: 48.6775922, longitude: 21.2986658,
         name: 'PhDr. Marta Šamová - MÁŠA',
         email: 'samova@post.sk',
         street: 'Vážecká 2',
         city: 'Košice 4',
         zip: '040 12',

       },{		id: 137, latitude: 48.5784672, longitude: 19.1287364,
         name: 'VITTEK CENTRUM  spol s r.o.',
         email: 'vittekcentrum@vittekcentrum.sk',
         street: 'Kozáčeka 10',
         city: 'Zvolen',
         zip: '960 01',

       },{		id: 138, latitude: 47.9964461, longitude: 17.6318014,
         name: 'ANDREA SHOP s.r.o.',
         email: 'Gabriel.Alfoldi@andrea-shop.sk',
         street: 'Galantská cesta 5855/22',
         city: 'Dunajská Streda',
         zip: '929 01',

       },{		id: 139, latitude: 47.8561897, longitude: 17.7680906,
         name: 'Condor trade, s.r.o.',
         email: 'vitekova@condorreal.sk',
         street: 'Kúpeľná 4',
         city: 'Veľký Meder',
         zip: '932 01',

                   },{		id: 140, latitude: 49.3080303, longitude: 20.6730336,
         name: 'JSM INVEST, s.r.o.',
         email: 'kulikjsm@gmail.com',
         street: 'Levočská 23',
         city: 'Stará Ľubovňa',
         zip: '064 01',

       },{
                   id: 141, latitude: 50.2167872, longitude: 12.1950642,
         name: 'Železářství Vojík',
         email: 'as@zelezarstvi-vojik.cz',
         street: 'Hlavní 2992',
         city: 'Aš',
         zip: '352 01',
       },{
                   id: 142, latitude: 50.2289642, longitude: 12.8680553,
         name: 'Železářství BEMO s.r.o.',
         email: 'tumova.bemo@volny.cz',
         street: 'Moskevská 14',
         city: 'Karlovy Vary',
         zip: '360 01',
       },
   // Add more data
 ]);

 const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'asc' });

 const requestSort = (key) => {
   let direction = 'asc';
   if (sortConfig.key === key && sortConfig.direction === 'asc') {
     direction = 'desc';
   }
   setSortConfig({ key, direction });
 };

 const getClassNamesFor = (name) => {
   if (!sortConfig) {
     return;
   }
   return sortConfig.key === name ? sortConfig.direction : undefined;
 };

 const sortedData = [...data].sort((a, b) => {
   if (a[sortConfig.key] < b[sortConfig.key]) {
     return sortConfig.direction === 'asc' ? -1 : 1;
   }
   if (a[sortConfig.key] > b[sortConfig.key]) {
     return sortConfig.direction === 'asc' ? 1 : -1;
   }
   return 0;
 });

  return (
  <div className="partners">
    <div className="grey">
      <div className="container">
        <h1>Staň se partnerem</h1>
        <ul className="partneri">
          <li>Nabízíme stovky výrobků pro úklid, sušení, žehlení a do kuchyňky v prémiové kvalitě, za kterou si stojíme</li>
          <li>Máme silné a stabilní postavení na českém i slovenském trhu</li>
          <li>Jsme flexibilní společnost, která okamžitě reaguje na požadavky trhu</li>
          <li>Zajišťujeme individuální přístup</li>
          <li>Garantujeme silnou marketingovou podporu – jak celostátní podporu značky LEIFHEIT (TV, on-line a další media), tak individuální podporu vaší prodejny (polepy výloh, bannery, poutače, plakáty a další – vše tvoříme na míru dané prodejně)</li>
          <li>Tvoříme pravidelné akční nabídky</li>
          <li>Naším cílem jsou noví a spokojení zákazníci</li>
        </ul>
      </div>

      </div>
      <div className="about-us">
        <div className="container">
          <h1 className="white">Velkoobchodní spolupráce</h1>
          <ul className="white velkoobchod">
            <li>Kontaktujte nás prostřednictvím mailu <a className="white" href="mailto:marketing@leifheit.cz">marketing@leifheit.cz</a></li>
            <li>Bude Vás kontaktovat příslušný regionální obchodní zástupce</li>
            <li>Dostanete od nás velkoobchodní ceník + podmínky spolupráce</li>
            <li>Dodáme Vám podklady k výrobkům (možnost pomocí feedu)</li>
            <li>Dle zájmu dostanete přístup do individualizovaného B2B portálu</li>
            <li>Zajistíme produktové školení</li>
            <li>Nabízíme možnost vybavení prodejen značkovými regály – ty nejen dodáme, ale i postavíme a naplníme tak, aby nabídka pro konečného zákazníka byla přehledná a generovala co největší obrat</li>
            <li>Lze zrealizovat předváděcí akci na Vaší prodejně</li>
            <li>Při splnění předem stanovených obratů možnost realizace roznosových letáků v okolí Vaší prodejny (letáky vytvoříme, vytiskneme a rozdistribuujeme na naše náklady)</li>
            <li>Doprava vždy zdarma (při splnění minimální objednávky)</li>

          </ul>
        </div>

        </div>
        <div className="container map">
        <h1 className="blue">Mapa Partnerů</h1>
        <MapContainer />


        </div>
        <div class="container Partneri-table">
        <table>
        <thead>
        <tr>
          <th
            onClick={() => requestSort('name')}
            className={`name header ${getClassNamesFor('name')}`}
          >
            Název firmy
          </th>
          <th onClick={() => requestSort('email')} className={`email header ${getClassNamesFor('email')}`}>
            E-mail
          </th>
          <th onClick={() => requestSort('street')} className={`street header ${getClassNamesFor('street')}`}>
            Adresa
          </th>
          <th onClick={() => requestSort('city')} className={`city header ${getClassNamesFor('city')}`}>
            Město
          </th>
          <th onClick={() => requestSort('zip')} className={`zip header ${getClassNamesFor('zip')}`}>
            PSČ
          </th>
        </tr>
      	</thead>
        <tbody>
          {sortedData.map((row) => (
            <tr key={row.id}>
              <td className="name">{row.name}</td>
              <td className="email">{row.email}</td>
              <td className="street">{row.street}</td>
              <td className="city">{row.city}</td>
              <td className="zip">{row.zip}</td>
            </tr>
          ))}
        </tbody>

        </table>
        </div>
    </div>
  );
}

export default Partneri;
