import React from 'react';
import { Row, Col } from 'react-bootstrap';
import phone from '../assets/frontend/svg/phone.svg'
import envelope from '../assets/frontend/svg/envelope.svg'

function Footer() {
  return (
    <div className="container footer">
      <h1 className="blue">Zůstaňte s námi <b>ve spojení</b></h1>
      <Row>
        <Col className="d-flex col-md-6 col-12 phone">
              <img className="phone-icon" src={phone}></img>
            <p className="red">Zavolejte nám Po-Pá: 7-17 h<br />
            <b className="number"><a href="tel: +420313104500">+420 313 104 500</a></b></p>
        </Col>
        <Col className="d-flex col-md-6 col-12 mail">
            <img className="envelope-icon" src={envelope}></img>
          <p className="red">Napište nám jakýkoliv dotaz<br />
          <b className="mail"><a href="mailto: kral@leifheit.cz">kral@leifheit.cz</a></b></p>
        </Col>
      </Row>
      <h3>Sledujte nás na sociálních sítích</h3>
      <Row className="feeds">
        <Col className="col-lg-3 facebook">
        <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></script>
        <div className="elfsight-app-15de7e56-76b6-442f-a02a-f17938b80250" data-elfsight-app-lazy></div>
        </Col>
      <Col className="col-lg-9 instagram">

      <div className="elfsight-app-fe1f3fa3-e1fe-40d0-8613-1555bf6273f6" data-elfsight-app-lazy></div>
        </Col>
      </Row>
      <p className="copyright">© LEIFHEIT CZ a.s.  |  Všechna práva vyhrazena
</p>
    </div>
  );
}

export default Footer;
