// src/components/CardGrid.js
import React from 'react';
import Card from './Card';
import card1 from '../assets/images/card1.png';
import card2 from '../assets/images/card2.png';
import card3 from '../assets/images/card3.png';
import card4 from '../assets/images/card4.png';

function CardGrid() {
  return (
    <div className="container">
      <div className="row row-cols-1 row-cols-lg-2 g-4">
        <div className="col-md-6">
          <Card
            imageUrl={card1}
            alt="Sušení a žehlení"
            title="Sušení a žehlení"
            buttonLink="https://www.leifheit-online.cz/prani-a-zehleni-c-634"
            buttonText="více"

          />
        </div>
        <div className="col-md-6">
          <Card
            imageUrl={card2}
            alt="Úklid"
            title="Úklid"
            buttonLink="https://www.leifheit-online.cz/uklid-c-640"
            buttonText="více"

          />
        </div>
        <div className="col-md-6">
          <Card
            imageUrl={card3}
            alt="Kuchyně"
            title="Kuchyně"
            buttonLink="https://www.leifheit-online.cz/kuchyne-c-608"
            buttonText="více"

          />
        </div>
          <div className="col-md-6">
            <Card
              imageUrl={card4}
              alt="Zdraví a krása"
              title="Zdraví a krása"
              buttonLink="https://www.leifheit-online.cz/zdravi-a-krasa-c-4124"
              buttonText="více"

            />
        </div>
        {/* Add more cards as needed */}
      </div>
    </div>
  );
}

export default CardGrid;
