import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

class MapContainer extends Component {
  componentDidMount() {
    // Get a reference to the Google Maps API instance
    const { google } = this.props;

    // Initialize and set up the Google Map
    const map = new google.maps.Map(this.map, {
      zoom: 7,
      center: { lat: 49.674738, lng: 15.820313 }, // Default center
    });


    // Array of marker locations
    const markerLocations = [
      { lat: 49.5299307, lng: 14.8024047, title: 'JIH-trade' },
      { lat: 50.0569329, lng: 14.5049998, title: 'Jestar-spol' },
      { lat: 50.1445204, lng: 14.0864231, title: 'Mercato-trade' },
      { lat: 48.7243464, lng: 14.6205065, title: 'Karel Malák' },
      { lat: 49.9974481, lng: 17.475102, title: 'ATEXKOM' },
      { lat: 49.5837224, lng: 15.934384, title: 'KINSKÝ Ždár' },
      { lat: 50.766701, lng: 15.0515081, title: 'Kování pro váš domov' },
      { lat: 49.23174, lng: 13.5161213, title: 'nářadí Janoušek' },
      { lat: 49.9737458, lng: 16.3990147, title: 'HAITECO CZ' },
      { lat: 50.1439399, lng: 14.0636855, title: 'Železářství Soukup' },
      { lat: 48.8510208, lng: 17.1256509, title: 'TVARBET MORAVIA' },
      { lat: 49.3541646, lng: 16.0126396, title: 'AMICUS' },
      { lat: 49.6794264, lng: 18.6652364, title: 'BABILON EDVARD - BERM' },
      { lat: 49.1427868, lng: 15.0008704, title: 'Železárství Jaroš' },
      { lat: 49.5929334, lng: 17.2492348, title: 'DOMA - R' },
      { lat: 50.3140401, lng: 13.5321605, title: 'V.H.V.' },
      { lat: 49.5310689, lng: 14.7900629, title: 'Holub Hobbymarket' },
      { lat: 49.3084895, lng: 18.1081883, title: 'Frydrych-pap' },
      { lat: 49.0104965, lng: 13.9958354, title: 'Obchodservis Prachatice' },
      { lat: 49.4780854, lng: 17.1105008, title: 'PLASTIMEX Prostějov ' },
      { lat: 49.8691059, lng: 18.3673757, title: 'ESAM' },
      { lat: 49.8352224, lng: 18.2863194, title: 'DOMA - R' },
      { lat: 49.7279229, lng: 17.2954642, title: 'Pavel BUNDIL' },
      { lat: 49.7047535, lng: 14.8959276, title: 'Lucie Vencelová - dom.potřeby' },
      { lat: 49.7704677, lng: 17.1099632, title: 'DOSTÁL CZ' },
      { lat: 48.764558, lng: 16.0623971, title: 'Domino - Excalibur City' },
      { lat: 50.0739423, lng: 14.4393019, title: 'DOMFIDA' },
      { lat: 50.0464939, lng: 14.4844728, title: 'Domino - Obchodní dům Centrum' },
      { lat: 49.688336, lng: 14.008298, title: 'Domácí potřeby-Franta' },
      { lat: 50.2630903, lng: 17.2372536, title: 'Prokauf Komplex' },
      { lat: 49.7842541, lng: 14.6851343, title: 'Domino' },
      { lat: 50.13398, lng: 14.1378851, title: 'Mercato Trade' },
      { lat: 49.6990132, lng: 17.0726516, title: 'DOSTÁL CZ' },
      { lat: 48.9951881, lng: 16.8578175, title: 'ZEVYT' },
      { lat: 49.9019421, lng: 18.3548407, title: 'ESAM' },
      { lat: 50.6571853, lng: 14.7220298, title: 'Rampas Aleš' },
      { lat: 48.8297548, lng: 16.3985858, title: 'Božena Sedláková - LUMIS' },
      { lat: 49.1954753, lng: 16.6093493, title: 'VICHR' },
      { lat: 49.4318138, lng: 15.2196338, title: 'Zdeněk Jaroš Ml. Železárství' },
      { lat: 49.7870036, lng: 18.2551468, title: 'Prumyslové zboží' },
      { lat: 49.8700702, lng: 18.4207951, title: 'ESAM' },
      { lat: 50.1536702, lng: 13.8977837, title: 'Železárství Soukup' },
      { lat: 50.6346906, lng: 15.1509194, title: 'Milena Súkeníková' },
      { lat: 49.4729238, lng: 17.1176157, title: 'Vladislav Škvaril - JASKVA' },
      { lat: 49.5720962, lng: 15.9401471, title: 'OD-Dr. Kinský' },
      { lat: 49.0245738, lng: 17.6448151, title: 'Domácí potřeby K&K' },
      { lat: 50.3440934, lng: 13.8184959, title: 'V.H.V.' },
      { lat: 50.1044979, lng: 14.3926778, title: 'Železárství na Podbabské' },
      { lat: 49.5464153, lng: 18.2110346, title: 'Eliška Kahánková' },
      { lat: 49.2010763, lng: 15.8871451, title: 'Haus Spezi NC Stop Shop' },
      { lat: 49.0888791, lng: 18.021282, title: 'EUROHITY' },
      { lat: 50.4293251, lng: 15.815853, title: 'INZAT' },
      { lat: 49.6746963, lng: 18.3379315, title: 'DOMA - R' },
      { lat: 49.4263304, lng: 15.603547, title: 'Zepo Jihlava' },
      { lat: 50.0868902, lng: 16.4629501, title: 'Železo Zdeněk- domácí potřeby' },
      { lat: 49.6384783, lng: 14.6384592, title: 'Jan Procházka' },
      { lat: 49.1482385, lng: 18.0205102, title: 'EUROHITY' },
      { lat: 50.0766499, lng: 14.4194915, title: 'GASTROŠULC' },
      { lat: 49.7807574, lng: 18.4292154, title: 'DOMA - R' },
      { lat: 49.3327845, lng: 17.5764425, title: 'Obchodní dům Morava Holešov' },
      { lat: 49.5772169, lng: 17.2884987, title: 'DOMA - R' },
      { lat: 49.9737492, lng: 16.3990147, title: 'HAITECO CZ' },
      { lat: 49.5772169, lng: 17.2884987, title: 'DMH a Kloupar' },
      { lat: 50.2423885, lng: 15.4887934, title: 'Jindřich Jireš - železárství' },
      { lat: 48.7359732, lng: 14.4818112, title: 'Barvy-laky Karel Malák' },
      { lat: 49.6384783, lng: 14.6384592, title: 'Průmyslové zboží Procházka Jan' },
      { lat: 49.3541829, lng: 16.0118385, title: 'AMICUS-zahrádka' },
      { lat: 49.0067277, lng: 14.7557933, title: 'Dušák s.r.o.' },
      { lat: 49.695703, lng: 14.9031597, title: 'GASTROŠULC s.r.o.' },
      { lat: 49.8661275, lng: 18.3737206, title: 'ESAM' },
      { lat: 49.9382424, lng: 17.9007358, title: 'DOMA - R ' },
      { lat: 49.2880239, lng: 16.2256727, title: 'Amicus' },
      { lat: 49.0524386, lng: 16.6240777, title: 'ZEPO' },
      { lat: 49.2239467, lng: 14.4154563, title: 'Elektro Maroušek' },
      { lat: 50.2272411, lng: 17.2065664, title: 'Prokauf Komplex' },
      { lat: 49.7186198, lng: 16.2558959, title: 'BÁČA POLIČKA' },
      { lat: 49.1393132, lng: 14.9860297, title: 'Mazdos' },
      { lat: 49.209973, lng: 15.8749555, title: 'Ing.Josef Prajsner' },
      { lat: 49.2504845, lng: 13.9095806, title: 'Jirí Milisterfer - AGROM' },
      { lat: 49.7047569, lng: 14.8959222, title: 'Lucie Vencelová' },
      { lat: 49.6781919, lng: 13.9943711, title: 'Roman Haering, domácí potřeby Prodok' },
      { lat: 49.5485233, lng: 17.7344923, title: 'Stami Hranice' },
      { lat: 49.7874211, lng: 14.6941199, title: 'Perfecta' },
      { lat: 49.2077318, lng: 16.148067, title: 'ELEKTRO - ŽELEZÁRSTVÍ DUFEK' },
      { lat: 49.6053952, lng: 18.1393165, title: 'Eliška Kahánková' },
      { lat: 49.8522019, lng: 18.554719, title: 'ESAM' },
      { lat: 49.8362698, lng: 18.2908899, title: 'DOMA - R' },
      { lat: 49.0488111, lng: 15.8096178, title: 'ZEPO' },
      { lat: 49.34019, lng: 17.9906969, title: 'ELPOS' },
      { lat: 49.6130802, lng: 17.25664, title: 'ATEXKOM' },
      { lat: 50.6122489, lng: 15.5060566, title: 'PKMT' },
      { lat: 48.9810136, lng: 16.5172222, title: 'ABK 99' },
      { lat: 49.3352055, lng: 17.9943444, title: 'ELPOS Josef Tkadlec' },
      { lat: 49.2419971, lng: 13.9034407, title: 'Milisterfer' },
      { lat: 49.2599255, lng: 15.2375997, title: 'ELEKTRA - Jana Janková' },
      { lat: 50.1273234, lng: 14.4664257, title: 'Jestar' },
      { lat: 50.7232758, lng: 15.1667048, title: 'Milena Súkeníková' },
      { lat: 50.7377087, lng: 15.2441338, title: 'Jiří Černý - železářství U BLACKA' },
      { lat: 50.0870043, lng: 16.4633112, title: 'Zdenek Železo' },
      { lat: 49.2238907, lng: 16.5903739, title: 'DMH a Kloupar' },
      { lat: 49.347668, lng: 16.0226952, title: 'Amicus' },
      { lat: 50.7716614, lng: 14.2028871, title: 'Železárství ABRAHAM' },
      { lat: 50.1132882, lng: 14.6108417, title: 'PKMT' },
      { lat: 49.4829748, lng: 17.2796525, title: 'Jiří Šilha - PRODOM' },
      { lat: 50.9550548, lng: 14.5510104, title: 'RATIO Rumburk' },
      { lat: 49.6384783, lng: 14.6384592, title: 'Jan Procházka' },
      { lat: 47.86406, lng: 17.7608458, title: 'Condor trade' },
      { lat: 49.4393598, lng: 18.7874989, title: 'EURONARADIE' },
      { lat: 49.0818021, lng: 19.6152098, title: 'UNIDOM' },
      { lat: 49.303836, lng: 21.3138161, title: 'Fecko Dušan - Predajňa u Fecka' },
      { lat: 48.344745, lng: 19.6578825, title: 'LG KRONO' },
      { lat: 49.0643495, lng: 18.9177121, title: 'Jaroslava Demácková-DEMRO' },
      { lat: 48.6249122, lng: 18.3972148, title: 'Záhradné centrum Unimarket' },
      { lat: 48.7208102, lng: 18.2638551, title: 'Ján Daniš -Drogéria' },
      { lat: 49.2139897, lng: 19.2953262, title: 'Domoss Technika' },
      { lat: 49.4425483, lng: 18.7855883, title: 'EURONARADIE Dielňa Záhrada' },
      { lat: 48.0336332, lng: 17.2977048, title: 'ANDREA SHOP' },
      { lat: 48.8920805, lng: 21.6768442, title: 'ELA VRANOV' },
      { lat: 48.7017144, lng: 21.7065431, title: 'Iveta Jakubová -TV - PLAY - SERVIS' },
      { lat: 48.6287892, lng: 18.3722425, title: 'BAJZIK' },
      { lat: 49.1384446, lng: 20.425876, title: 'FARBY-LAKY-DROGERIA' },
      { lat: 48.5753493, lng: 19.1494663, title: 'VITTEK CENTRUM' },
      { lat: 48.7034672, lng: 21.6769539, title: 'Iveta Jakubová - TV - PLAY - SERVIS' },
      { lat: 48.3772341, lng: 17.5846088, title: 'Jaroslav Hudec, Domace potreby, OD Jednota' },
      { lat: 48.1276435, lng: 17.1128537, title: 'František Majtán - EURONICS TPD' },
      { lat: 49.1314748, lng: 20.427396, title: 'Farby-Laky Bjaloncík' },
      { lat: 49.3024235, lng: 21.2783154, title: 'Dušan Fecko Drogeria' },
      { lat: 48.676625, lng: 21.297629, title: 'PhDr. Marta Šamová - MÁŠA' },
      { lat: 48.2038008, lng: 17.6335595, title: 'REMI - DP' },
      { lat: 48.7456182, lng: 18.3340797, title: 'Ján Daniš' },
      { lat: 48.5929394, lng: 17.8205849, title: 'DOMOSS TECHNIKA' },
      { lat: 48.3368399, lng: 19.6503355, title: 'Miroslav Tóth ELEKTRO - M.T.' },
      { lat: 48.1487536, lng: 17.8693807, title: 'ANDREA SHOP' },
      { lat: 48.332384, lng: 19.6679916, title: 'Miroslav Tóth ELEKTRO - M.T.' },
      { lat: 48.6776095, lng: 21.2961171, title: 'PhDr. Marta Šamová - MÁŠA' },
      { lat: 48.5784747, lng: 19.1261918, title: 'VITTEK CENTRUM' },
      { lat: 47.9964443, lng: 17.6292483, title: 'ANDREA SHOP' },
      { lat: 47.856188, lng: 17.7655393, title: 'Condor trade' },
      { lat: 49.2929642, lng: 20.6882773, title: 'JSM INVEST' },
      { lat: 50.2167905, lng: 12.1924198, title: 'Železářství Vojík' },
      { lat: 50.2289783, lng: 12.8654962, title: 'Železářství BEMO' },


      // Add more marker locations as needed
    ];

    // Create markers for each location
    markerLocations.forEach((location) => {
      new google.maps.Marker({
        position: { lat: location.lat, lng: location.lng }, // Marker's position
        map, // The map to add the marker to
        title: location.title, // Marker's title (tooltip)
      });
    });
  }

  render() {
    return (
      <div
        ref={(map) => {
          this.map = map; // Store a reference to the map's div element
        }}
        style={{ width: '100%', height: '700px' }}
      >
        {/* Map will be rendered here */}
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAQ6tBk7U0V85JQqOrnQB_15f0VR4qZUb8', // Replace with your Google Maps API key
})(MapContainer);
