import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

class KontaktMapa extends Component {
  componentDidMount() {
    // Get a reference to the Google Maps API instance
    const { google } = this.props;

    // Initialize and set up the Google Map
    const map = new google.maps.Map(this.map, {
      zoom: 11,
      center: { lat: 50.0308124, lng: 14.2782508 }, // Default center
    });


    // Array of marker locations
    const markerLocations = [
      { lat: 50.0308124, lng: 14.2782508, title: 'Leifheit' },
    ];

    // Create markers for each location
    markerLocations.forEach((location) => {
      new google.maps.Marker({
        position: { lat: location.lat, lng: location.lng }, // Marker's position
        map, // The map to add the marker to
        title: location.title, // Marker's title (tooltip)
      });
    });
  }

  render() {
    return (
      <div
        ref={(map) => {
          this.map = map; // Store a reference to the map's div element
        }}
        style={{ width: '100%', height: '250px' }}
      >
        {/* Map will be rendered here */}
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAQ6tBk7U0V85JQqOrnQB_15f0VR4qZUb8', // Replace with your Google Maps API key
})(KontaktMapa);
