import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import alza from '../assets/images/alza.png';
import obi from '../assets/images/obi.png';
import hornbach from '../assets/images/Hornbach.png';
import landema from '../assets/images/landema.jpeg';
import kaufland from '../assets/images/kaufland.png';
import eva from '../assets/images/eva.png';
import datart from '../assets/images/datart.png';
import home from '../assets/images/4home.png';
import tesco from '../assets/images/Tesco.png';
import mall from '../assets/images/mall.png';
import bonami from '../assets/images/Bonami.png';
import globus from '../assets/images/globus.jpg';
import sanitino from '../assets/images/sanitino.png';
import ahold from '../assets/images/Ahold.png';

const PartneriCarousel = () => {
  const isMobile = window.innerWidth < 768; // Define a threshold for mobile screens

  return (
    <div className="row container partner-carousel">
      <div className="col-lg-3 col-md-12 d-none d-md-block">
        <h1 className="blue left">
          Naši <br className="d-none d-lg-block" />
          <b>Partněři</b>
        </h1>
      </div>
      <div className={`col-lg-9 col-md-12 d-md-block d-none ${isMobile ? 'd-grid' : ''}`}>
        <Carousel
          showArrows={true}
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          infiniteLoop={true}
          emulateTouch={true}
          selectedItem={0} // Initially selected item
          interval={3000} // Auto-slide interval in milliseconds (set to 0 to disable)
          transitionTime={500} // Transition duration in milliseconds
          centerMode={true} // Use centerMode on mobile screens
          centerSlidePercentage={33} // Display one slide at a time on mobile
          useKeyboardArrows={true} // Enable keyboard navigation
        >
          <div>
            {/* Content for Slide 1 */}
            <img src={alza} alt="alza" className="alza" />
          </div>
          <div>
            {/* Content for Slide 2 */}
            <img src={obi} alt="obi" />
          </div>
          <div>
            {/* Content for Slide 3 */}
            <img src={hornbach} alt="hornbach" />
          </div>
          <div>
            {/* Content for Slide 4 */}
            <img src={landema} alt="landema" className="landema" />
          </div>
          <div>
            {/* Content for Slide 5 */}
            <img src={kaufland} alt="kaufland" className="kaufland" />
          </div>
          <div>
            {/* Content for Slide 6 */}
            <img src={eva} alt="eva" className="eva" />
          </div>
          <div>
            {/* Content for Slide 6 */}
            <img src={datart} alt="datart" className="datart" />
          </div>
          <div>
            {/* Content for Slide 6 */}
            <img src={home} alt="home" className="home" />
          </div>
          <div>
            {/* Content for Slide 6 */}
            <img src={tesco} alt="tesco" className="tesco" />
          </div>
          <div>
            {/* Content for Slide 6 */}
            <img src={mall} alt="mall" className="mall" />
          </div>
          <div>
            {/* Content for Slide 6 */}
            <img src={bonami} alt="bonami" className="bonami" />
          </div>
          <div>
            {/* Content for Slide 6 */}
            <img src={globus} alt="globus" className="globus" />
          </div>
          <div>
            {/* Content for Slide 6 */}
            <img src={sanitino} alt="sanitino" className="sanitino" />
          </div>
          <div>
            {/* Content for Slide 6 */}
            <img src={ahold} alt="ahold" className="ahold" />
          </div>
          {/* Add more slides as needed */}
        </Carousel>
      </div>
    </div>
  );
};

export default PartneriCarousel;
