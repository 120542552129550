//Textové Karty na hp
import React from 'react';

function CardText(props) {
  return(
    <div className="col-lg-3 col-md-6 col-12">
      <div className="row">
        <div className="col-12">
        <img src={props.imageUrl} className="top-image" alt={props.alt} />
        </div>
        <div className="col-12">
        <h4>
      {props.header}
        </h4>
          <p className="card-text">
          {props.text}
          </p>
        </div>
      </div>
    </div>

  )};
export default CardText;
