// src/components/Home.js
import React from 'react';
import prodejna from '../assets/images/leifheit_prodejna.jpg'
import KontaktMapa from '../components/kontaktMap.js';
import Contact from '../components/contactform.js';

function Kontakt() {
  return (

    <div>
      <div className="container-xl kontakt">
        <div className="row">
          <div className="col-6">
            <h2 className="">Kontaktní údaje</h2>
            <p className="">LEIFHEIT CZ a.s. <br />
              Logistický areál - Logport Prague West<br />
              Mezi stromy - hala O3<br />
              252 25 Jinočany - Praha západ<br />
              Česká republika<br /><br />
              IČ: 28221991<br />
              DIČ: CZ28221991<br />
              Společnost zapsaná v obchodním rejstříku: MS v Praze, oddíl B, vložka 13727<br />
            </p>
            </div>
        <div className="col-6">
        <KontaktMapa />
        </div>
        </div>
      </div>
      <div className="about-us">
        <div className="container-xl">
          <h1 className="white">Navštivte nás v nové prodejně!</h1>
          <div className="row">

            <div class="col-lg-6 col-12">
             <p className="white">
             Nyní máte jedinečnou příležitost vidět a zakoupit celý sortiment naší značky na jednom místě. Přijďte za námi a objevte, co pro vás máme připraveno
             </p>
             <p className="white"> Otevírací doba prodejny: <br />
             Po-Pa 7:00-17:00<br />
             So –Ne  Zavřeno<br />
             </p>
             <p className="white">Adresa prodejny:<br />
             Logport Prague West - logistický areál<br />
             Mezi stromy (hala č.3)<br />
             25225 Jinočany<br />
             Tel.: +420 313 104 500
             </p>
            </div>
            <div class="col-lg-6 col-12">
              <img className="prodejna" src={prodejna} alt="Firemní prodejna" />
            </div>
          </div>
        </div>
      </div>
      <div className="about-us">
        <div className="container-xl">
                  <Contact />
        </div>
      </div>

    </div>
  );
}

export default Kontakt;
