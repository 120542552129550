import React from 'react';
import carousel1 from '../assets/images/carousel1.png';
import carousel2 from '../assets/images/carousel2.png';


class Carousel extends React.Component {
  render() {
    return (
      <div className="container-xl">
        <h1 className="blue">Co je u nás <b>nového?</b></h1>
        <div id="myCarousel" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={carousel1} alt="Image 1" />
              <div className="carousel-caption d-md-block">
                <h1 className="blue">Připravili jsme nové <br/> <b>čisticí produkty</b></h1>
                <a className="btn" href="https://www.leifheit-online.cz/leifheit-set-clean-twist-disc-mop-mobile-52102-p-16714">Prohlédnout produkty</a>
              </div>
            </div>
            <div className="carousel-item">
              <img src={carousel2} alt="Image 2" />
              <div className="carousel-caption d-none d-md-block">
                <h5>test2</h5>
                <p><a href="#">Prohlédnout produkty</a></p>
              </div>
            </div>
            <div className="carousel-item">
              <img src={carousel1} alt="Image 3" />
              <div className="carousel-caption d-none d-md-block">
                <h5>test3</h5>
                <p><a href="">Prohlédnout produkty</a></p>
              </div>
            </div>
          </div>

          <a className="hide carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only"></span>
          </a>
          <a className="hide carousel-control-next" href="#myCarousel" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only"></span>
          </a>
        </div>
      </div>
    );
  }
}

export default Carousel;
