// src/components/Card.js
import React from 'react';
import aboutUs from '../assets/images/about-us.jpg';
import { Link } from 'react-router-dom';

function AboutUs() {
  const textWithLineBreaks = "LEIFHEIT CZ a. s. je obchodní společnost působící na českém a slovenském trhu již 30 let jako výhradní distributor značek Leifheit a Soehnle. Je pobočkou německé mateřské společnosti LEIFHEIT AG, se sídlem v Nassau (D). Sortiment nabízených produktů zahrnuje širokou škálu domácích potřeb a spotřebičů.\n V současné době firma sídlí v logistickém areálu Logport Praha – Jinočany, odkud koordinuje svou činnost na obou trzích, s důrazem na kvalitu a zákaznický servis.";

  const paragraphs = textWithLineBreaks.split('\n').map((line, index) => (
    <p className="white" key={index}>{line}</p>
  ));

  return (
    <div className="about-us">
      <div className="container-xl">
        <h1 className="white">O společnosti <b>Leifheit</b></h1>
        <div className="row">
          <div className="col-lg-6 col-12">
            <img src={aboutUs} alt="About-us" />
          </div>
          <div className="col-lg-6 col-12">
            {paragraphs}
           <Link className="btn move_right" to="/pages/o-nas">Více o společnosti</Link>
           <a className="btn cart" href="https://www.leifheit-online.cz/">Přejít na e-shop</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
