import React, { useState } from 'react';
import { Navbar, Nav, Row, Col } from 'react-bootstrap';
import leifheit_logo from '../assets/images/leifheit_logo.png';
import facebook from '../assets/frontend/svg/facebook.svg';
import instagram from '../assets/frontend/svg/instagram.svg';
import youtube from '../assets/frontend/svg/youtube.svg';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';

const Header = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="sticky-top">
      <Row className="top-header container g-0">
        <Col className="g-0">
          <span className="phone-number">Infolinka: <a href="tel:+420 313 104 500">+420 313 104 500</a> (Po-Pá: 7-17 h)</span>
          <span className="icon"><a href="https://www.facebook.com/profile.php?id=100064911570984" target="_blank"><img src={facebook}></img></a></span>
          <span className="icon"><a href="https://www.instagram.com/leifheitcz/" target="_blank"><img src={instagram}></img></a></span>
          <span className="icon"><a href="https://www.youtube.com/@leifheit-cz/featured" target="_blank"><img src={youtube}></img></a></span>
        </Col>
      </Row>

    <Navbar
      expand="lg"
      expanded={expanded}
    >

      <Container>
        <Navbar.Brand  className="col-lg-6" href="/"><img src={leifheit_logo} alt="Logo" /></Navbar.Brand>
                <a href="https://www.leifheit-online.cz" target="_blank" className="d-lg-inline-flex d-lg-none btn cart">E-SHOP</a>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
      <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/pages/o-nas" activeClassName="active">
              O nás
            </Nav.Link>
            <Nav.Link as={Link} to="/pages/partneri" activeClassName="active">
              Partneři
            </Nav.Link>
            <Nav.Link as={Link} to="/pages/Kontakt" activeClassName="active">
              Kontakt
            </Nav.Link>

          </Nav>
        </Navbar.Collapse>
        <a href="https://www.leifheit-online.cz" target="_blank" className="d-lg-inline-flex d-none btn cart">E-SHOP</a>
      </Container>
    </Navbar>
    </div>
  );
};

export default Header;
