import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/homepage';
import Uvod from './pages/uvod';
import Onas from './pages/o-nas';
import Partneri from './pages/partneri';
import Kontakt from './pages/kontakt';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop.js';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <div className="App containter">
    <Helmet>
          <title>LEIFHEIT CZ a.s.</title>
          <meta name='description' content='Výhradní dovozce potřeby pro dům, byt a zahradu německé značky Leifheit do České a Slovenské republiky.' />
          <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></script>
          <link rel="icon" href="%PUBLIC_URL%/assets/images/favicon.ico" />
          </Helmet>
    <Router>
      <Header /> {/* Include the navigation component */}
      <ScrollToTop />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/pages/uvod" element={<Uvod />} />
        <Route path="/pages/o-nas" element={<Onas />} />
        <Route path="/pages/partneri" element={<Partneri />} />
        <Route path="/pages/kontakt" element={<Kontakt />} />
      </Routes>
    </Router>

          <Footer />

    </div>
  );
}

export default App;
