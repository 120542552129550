import React from 'react';
import CardGrid from '../components/CardGrid';
import AboutUs from '../components/About-us';
import Text from '../components/Text';
import Carousel from '../components/Carousel';
import PartneriCarousel from '../components/Partners';


function Home() {
  return (

    <div className="app">
        <h1 className="topheader blue">Inspirace pro <b>vaši domácnost</b></h1>
      <CardGrid />
      <Text />
      <Carousel />
      <PartneriCarousel />
      <AboutUs />

    </div>
  );
}

export default Home;
